<template>
  <div class="news-page">
    <!-- 会员单位介绍 -->
    <main-nav />
    <div class="news-bar">
      <div class="bannerWrapper">
        <img src="./../../assets/B4.jpg" alt="" />
      </div>
    </div>
    <div class="newsWrapper">
      <div class="newsItem">
        <div class="newsLeftItem">
          <img src="../../assets/huaiHuaMerchantsNews/OurActivity/1.1.jpg" alt="" />
        </div>
        <div class="newsRightItem" @click="toDetail(12)">
          <p>“2021湖南--粤港澳大湾区投资贸易洽谈活动” 广东省湖南怀化商会会长郑能欢应邀发言</p>
          <div class="ItemContent newsOmit"> 
          </div>
        </div>
      </div>
      <div class="newsItem">
        <div class="newsLeftItem">
          <img src="../../assets/huaiHuaMerchantsNews/OurActivity/1.1.jpg" alt="" />
        </div>
        <div class="newsRightItem" @click="toDetail(1)">
          <p>【广东省湖南怀化商会会长郑能欢一行赴怀考察记】</p>
          <div class="ItemContent newsOmit"> 
          </div>
        </div>
      </div>
      <div class="newsItem">
        <div class="newsLeftItem">
          <img src="../../assets/huaiHuaMerchantsNews/OurActivity/2.1.jpg" alt="" />
        </div>
        <div class="newsRightItem" @click="toDetail(2)">
          <p>【交流】怀化市人民政府李卫林副市长一行考察广东省湖南怀化商会</p>
          <div class="ItemContent newsOmit">
            
          </div>
        </div>
      </div>
      <div class="newsItem">
        <div class="newsLeftItem">
          <img src="../../assets/huaiHuaMerchantsNews/OurActivity/3.1.jpg" alt="" />
        </div>
        <div class="newsRightItem" @click="toDetail(3)">
          <p>【活动】商会新会员授牌仪式及与鑫梓润集团签署战略合作协议</p>
          <div class="ItemContent newsOmit">
            
          </div>
        </div>
      </div>
    </div>
    
    <back-to-top :custom-style="myBackToTopStyle" :visibility-height="300" :back-position="0" transition-name="fade" />

    <Footer />
  </div>
</template>

<script>
import MainNav from "../../components/MainNav/index";
import Footer from "../../components/Footer.vue";
export default {
  name: "eleDem",
  data() {
    return {
      typeMark: 0, // 默认为 供应
      pageNum: 2, // 总页
      currentPage: 1, // 当前页
      stripNum: 6, // 页面条数
      myBackToTopStyle: {
        right: '50px',
        bottom: '50px',
        width: '6rem',
        height: '6rem',
        'border-radius': '50%',
        'line-height': '45px', // 请保持与高度一致以垂直居中
      },
    };
  },
  components: {
    MainNav,
    Footer
  },
  mounted() {
  },
  methods: {
    changeOptions(bool) {
      this.typeMark = bool;
      this.currentPage = 1;
      if (bool == 0) {
        this.pageNum = 2;
      } else {
        this.pageNum = 1;
      }
    },
    // 点击跳转详情页
    toDetail(index) {
      this.$router.push({
        name: 'Activity'+index,
      });
    }
  },
};
</script>

<style scoped lang="less">
@import "../../styles/news";
.news-page {
  .news-bar {
    height: auto;
    position: relative;
    margin-bottom: 2%;
    .bannerWrapper {
      width: 100%;
      height: 500px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .pagenav {
    margin-top: 2.71%;
    margin-bottom: 4.67%;
    text-align: center;
    .pagination {
      li {
        .active {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
        a {
          margin-right: 0.83rem;
        }
        a:hover {
          border-color: #2b8dfa;
          background-color: #ffffff;
        }
        a:focus {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
      }
    }
  }
}


@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .news-page .news-bar /deep/.newsListNav {
    height: 6rem;
    .ListNav/deep/ {
      margin-right: 15rem;
    }
  }
}
</style>